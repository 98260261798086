* {
    font-family: 'Kanit', 'sans-serif';
}

html {
    font-size: 16px;
    scroll-behavior: smooth;
}

img > .product {
    display: block;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

#root {
    min-height: 100vh;
}

#wrap {
    min-height: 100vh;
}

#context {
    min-height: calc(100vh - 258px - 1rem);
    padding: auto 10px;
}

h4.text-light {
    margin: auto auto auto 1rem;
}

h5.text-light {
    margin: auto auto auto auto;
}

div.noPadding {
    padding-left: 0;
    padding-right: 0;
}

div.noMargin {
    margin-left: 0;
    margin-right: 0;
}

/* For Blank Content */

div.box {
    /* height: 800px; */
    background-color: whitesmoke;
}

.disable-dbl-tap-zoom {
    touch-action: manipulation;
}

.btn.btn-tangpark {
  background-color: #ed1b24;
  color:whitesmoke;
}

.btn.btn-tangpark:hover {
  background-color: #ed1b24;
  color: #c8c8c8;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23101010' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23101010' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

@media only screen and (min-width: 992px) {
    * {
        font-size: 16px;
    }
}

@media only screen and (max-width: 991px) {
    * {
        font-size: 14px;
    }
}