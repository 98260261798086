.hamburger {
    z-index: 1022;
    position: absolute;
    right: 8px;
    top: 4px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.line {
    height: 2px;
    width: 25px;
    background-color: #2C2C2C;
    transition: all 0.2s ease;
}

.line.open {
    position: fixed;
}

.line-top {
    transform: none;
    transform-origin: top left;
    margin-bottom: 5px;
}

.line-top.open {
    transform: translate(3px, 4px)  rotate(45deg);
    margin-bottom: 35px;
}

.line-middle {
    opacity: 1;
    transform: none;
}

.line-middle.open {
    opacity: 0;
    transform: translateX(-16px);
}

.line-bottom {
    transform: none;
    transform-origin: top left;
    margin-top: 5px;
}

.line-bottom.open {
    transform: translate(1px, 8px) rotate(-45deg);
    margin-bottom: 14px;
}