/* Header Style */
.header-container {
    height: 61px;
    background-color: whitesmoke;
    border-bottom: 2px solid #ed1b24;
}

li.active {
    border-radius: 5px;
    align-content: center;
}

.menu-text {
    color: #2C2C2C;
    transform: skew(30deg);
    -webkit-transform: skew(30deg);
    -moz-transform: skew(30deg);
    -o-transform: skew(30deg);
    font-size: 1.4rem !important;
    text-decoration: underline !important;
}

.navbar-nav {
    margin-left: -35px;
    transform: skew(-30deg);
    -webkit-transform: skew(-30deg);
    -moz-transform: skew(-30deg);
    -o-transform: skew(-30deg);
    background-color: whitesmoke;
}

.ui.menu .item:before {
    background: transparent;
}

.header-logo-container {
    margin-left: -30px;
    padding-left: 50px;
    height: 60px;
    width: 520px;
    transform: skew(-30deg);
    -webkit-transform: skew(-30deg);
    -moz-transform: skew(-30deg);
    -o-transform: skew(-30deg);
    background-color: #6f6f6f;
}

.header-logo {
    height: 35px;
    transform: skew(30deg);
    -webkit-transform: skew(30deg);
    -moz-transform: skew(30deg);
    -o-transform: skew(30deg);
}

.navLink-container {
    width: 150px;
    height: 60px;
}
.active > .navLink-container {
    background-color: #ed1b24;
}
.active > .navLink-container > .menu-text{
    color: whitesmoke;
}

@media only screen and (max-width: 991px) {
    .header-container {
        height: 44px;
    }

    .header-logo-container {
        margin-left: -20px;
        padding-left: 28px;
        height: 42px;
        width: 320px;
        transform: skew(-30deg);
        -webkit-transform: skew(-30deg);
        -moz-transform: skew(-30deg);
        -o-transform: skew(-30deg);
        background-color: #6f6f6f;
    }
    
    .header-logo {
        height: 22px;
        transform: skew(30deg);
        -webkit-transform: skew(30deg);
        -moz-transform: skew(30deg);
        -o-transform: skew(30deg);
    }
}

