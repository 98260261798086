.product-type-container {
  background-color: whitesmoke;
}

.section-header {
  height: 134px;
}

.section-header-label {
  margin: 0px 20px;
  font-size: 2rem;
}

.section-header-line {
  width: 290px;
  height: 1px;
  background-color: #ed1b24;
  border: 2px solid #ed1b24;
}

.product-type-button {
  width: 288px;
  height: 86px;
  background-color: #ed1b24;
  margin: 0px 25px 0px 25px;
  transform: skew(-30deg);
  -webkit-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  border: none;
}

.product-type-icon {
  width: 80px;
  filter: brightness(0) invert(1);
  transform: skew(30deg);
  -webkit-transform: skew(30deg);
  -moz-transform: skew(30deg);
  -o-transform: skew(30deg);
}

.product-type-text {
  margin-left: 20px;
  transform: skew(30deg);
  -webkit-transform: skew(30deg);
  -moz-transform: skew(30deg);
  -o-transform: skew(30deg);
}

.product-type-text>span {
  font-size: 1.4rem;
  font-weight: 400;
  color: whitesmoke;
}

.product-type-button.active {
  background-color: #F5F5F5;
  border: 4px solid #ed1b24;
}

.product-type-button.active>div>span {
  font-weight: 700;
  color: #ed1b24;
}

.product-type-button.active>img {
  filter: none;
}

#wrapped-product-banner {
  border-top: 2px solid #c0c0c0;
}

.overlap-container {
  position: relative;
}

#wrapped-triangle {
  width: 100px;
  height: 50px;
  left: calc(50% - 50px);
  top: 0px;
}

#triangle-down {
  width: 0;
  height: 0;
  border-left: 36px solid transparent;
  border-right: 36px solid transparent;
  border-top: 32px solid #F5F5F5;
  left: calc(50% - 18px);
  top: -2px;
}

#triangle-down-shadow {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-top: 36px solid #C0C0C0;
  left: calc(50% - 22px);
  top: -2px;
}

.overlap {
  display: block;
  position: absolute;
}

.product-banner {
  height: auto;
  width: 100%;
}

.wrapped-product-button {
  left: 50%;
  width: calc(50% - 10.5px);
  bottom: 25px;
}

.product-button-container>img:hover {
  cursor: pointer;
}

@media only screen and (min-width: 992px) {
  .scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .scrollbar:hover::-webkit-scrollbar {
    display: block;
    position: absolute;;    
    height: 10px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background: #c0c0c0;
    border-radius: 5px;
  }
  
  .scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  
  .scrollbar:hover::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .model-carousel {
    width: 85%;
    height: 200px;
    overflow-x: hidden;
    margin: 0px auto 20px auto;
  }

  .scrollbar:hover {
    height: 210px;
    overflow-x: auto;
    margin: 0px auto 10px auto;
  }

  .model-container {
    width: 240px;
    margin: auto 15px;
  }
  
  .model-container:hover {
    cursor: pointer;
  }

  .model-cover {
    width: 240px;
    filter: saturate(50%) contrast(40%) brightness(70%);
    height: auto;
  }

  .model-cover.active {
    filter: none;
  }

  .product-button {
    height: 40px;
    width: 160px;
    margin: 0px 7.5px;
    border-radius: 30px;
  }

  .brochure-button {
    height: 40px;
    width: 200px;
    margin: 0px 7.5px;
    border-radius: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .product-type-container {
    background-color: whitesmoke;
  }

  .section-header {
    height: 43px;
  }

  .section-header-label {
    font-size: 1.2rem;
  }

  .section-header-line {
    width: 10%;
  }

  .product-type-button {
    width: 100px;
    height: 30px;
    padding: 0px;
    margin: 0px 5px 0px 5px;
  }

  .product-type-button.active {
    background-color: #F5F5F5;
    border: 2px solid #ed1b24;
  }

  .product-type-icon {
    width: 25px;
  }
  
  .product-type-text {
    margin: 0px 0px 4px 2px;
  }
  
  .product-type-text>span {
    font-size: .6rem;
    font-weight: 400;
  }

  .model-carousel {
    width: 90%;
    overflow-x: none;
    margin: 0px auto 0px auto;
    object-fit: contain;
  }
  
  .model-cover {
    width: 80%;
    height: auto;
  }
  
  #wrapped-triangle {
    width: 100px;
    height: 50px;
    left: calc(50% - 50px);
    top: 0px;
  }
  
  #triangle-down {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #F5F5F5;
    left: calc(50% - 10px);
    top: -2px;
  }
  
  #triangle-down-shadow {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #C0C0C0;
    left: calc(50% - 12px);
    top: -2px;
  }

  .wrapped-product-button {
    bottom: 10px;
  }

  .wrapped-product-button.second-row { 
    bottom: 35px;
  }

  .product-button {
    height: 20px;
    width: 80px;
    margin: 0px 2.5px;
    border-radius: 15px;
    font-size: .5rem;
  }

  .brochure-button {
    height: 20px;
    width: 100px;
    margin: 0px 2.5px;
    border-radius: 15px;
    font-size: .5rem;
  }

  .carousel-control-prev, .carousel-control-next {
    width: 5%;
  }
  
  .model-carousel .carousel-indicators {
    position: relative;
    margin: 10px auto 5px auto;
  }

  .model-carousel .carousel-indicators li {
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background-color: #C0C0C0;
  }
  
  .model-carousel .carousel-indicators li.active {
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background-color: #101010;
  }
}