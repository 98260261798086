#mobile-sidebar {
    position: fixed;
    z-index: 999;
    width: 250px;
    background-color: rgba(245, 245, 245, 0.95);
    top: 44px;
    right: 0px;
    transform: translateX(250px);
    border-radius: 0px 0px 0px 7px;
    transition: transform .8s;
}

#mobile-sidebar.active {
    transform: translateX(0px);
}

#mobile-sidebar>* {
    font-size: 2.5rem;
    color: white;
}

.sidebar-menu {
    width: 180px;
    height: 35px;
    background-color: #6F6F6F;
    color: whitesmoke;
    margin: 10px 10px 0px 0px;
    font-size: 1.25rem;
    border-radius: 10px;
}

.active > .sidebar-menu {
    background-color: #e20514;
    margin-right: 60px;
}

#mobile-sidebar-footer {
    height: 15px;
    margin-top: 15px;
    border-radius: 0px 0px 0px 7px;
    background-color: #e20514;
}