.slider-page {
  display: block;
  background-color: rgba(0,0,0,0.3);
  box-shadow: 0px 0px 0.5rem rgb(0, 0, 0);
  padding: 5px 20px;
  max-width: 250px;
  position: absolute;
  bottom: 30px;
}

.page-number {
  font-size: 1.2rem;
  color: whitesmoke;
}

.slide-image-container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  object-fit: contain;
}

.slide-image {
  max-width: 80%;
  min-height: 350px;
  max-height: 450px;
  object-fit: contain;
}

.styled-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: auto 5px;
}

.arrow {
  color: whitesmoke;
  text-shadow: 0px 0px 0.5rem #000000;
  font-size: 3rem;
}

.left.arrow {
  position: absolute;
  left: 20px;
}

.right.arrow {
  position: absolute;
  right: 20px;
}

@media only screen and (max-width: 991px) {
  .slider-page {
    width: 20%;
    left: 40%;
    bottom: 1.5rem;
  }

  .slide-image-container {
    max-width: 90%;
    min-height: 250px;
  }

  .slide-image {
    max-width: 100%;
    min-height: 250px;
    object-fit: contain;
  }

  .arrow {
    color: whitesmoke;
    text-shadow: 0px 0px 0.3rem #000000;
    font-size: 1.5rem;
  }

  .left.arrow {
    position: absolute;
    top: 50%;
    left: -5px;
  }
  
  .right.arrow {
    position: absolute;
    top: 50%;
    right: -5px;
  }
}