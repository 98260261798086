.banner-carousel {
  width: 100%;
  height: 30rem;
  margin: auto;
}

#carouselExampleIndicators {
  background-color: #343a40;
}

.carousel-control-prev, .carousel-control-next {
  width: 5%;
}

.carousel .carousel-indicators li {
  height: 12px;
  width: 12px;
  border-radius: 6px;
  border-width: 1px;
  border-color: transparent;
  background-color: whitesmoke;
}

.carousel .carousel-indicators li.active {
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: var(--white);
}