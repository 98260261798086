.embed-media-container {
  position: relative;
  height: 0px;
  overflow: hidden;
  margin-top: 15px;
  padding: 30px 0px 60% 0px;
}

.youtube-exp-container {
  background-color: grey;
}

@media only screen and (min-width: 992px) {
  .gallery-container {
    width: 55%;
  }

  .gallery-container img {
    width: 100%;
  }

  .youtube-exp-container {
    width: 45%;
    padding: 20px 10px 0px 10px;
  }

  .media-logo {
    width: 80px;
    height: 80px;
  }

  .media-title {
    margin-left: 1rem;
    font-size: 1.6rem;
  }

  .embed-media-container iframe, .embed-media-container object, .embed-media-container embed {
    position: absolute;
    top: 0px;
    left: 5%;
    width: 90%;
    height: 90%;
  }
}

@media only screen and (max-width: 991px) {
  .gallery-container {
    width: 100%;
  }

  .gallery-container img {
    width: 100%;
  }

  .youtube-exp-container {
    width: 100%;
    margin: 0px;
    padding: 20px 10px 0px 10px;
  }

  .media-logo {
    width: 2.5rem;
    height: 2.5rem;
  }

  .embed-media-container iframe, .embed-media-container object, .embed-media-container embed {
    position: absolute;
    top: 0;
    left: 5%;
    width: 90%;
    height: 90%;
  }

  .media-title {
    margin-left: 10px;
    font-size: 1.1rem;
  }
}