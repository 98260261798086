.fade-in-section {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
}

.slide-left {
    transform: translate(-10vw, 0vh);
}

.slide-right {
    transform: translate(10vw, 0vh);
}

.slide-up {
    transform: translate(0vw, -10vh);
}

.slide-down {
    transform: translate(0vw, 10vh);
}
.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}

.fade-in {
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
}