.branch-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    padding: .5rem;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: #cccccc;
    /* background-color: #cccccc; */
}

.branch-header {
    /* margin: auto 10px; */
    padding: 8px 12px 5px;
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 1px;
    border-color: #cccccc;
}

.branch-map {
    margin: 8px auto;
    width: 95%;
}

.branch-body > p {
    margin: 5px 0px 5px 0px;
    padding: 5px 15px 5px;
}

.branch-map-container {
    margin-top: 8px;
    padding-left: 15px;
}

.branch-list {
    padding-bottom: 10px;
    margin: 15px auto;
    border-style: solid;
    border-color: #cccccc;
    border-width: 0px;
    border-bottom-width: 1px;
}